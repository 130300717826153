export const sidebarMenuItems = [
  {
    title: "User Management",
    icon: "users",
    url: "/users",
    isVisibleForRoles: ['Admin']
  },
  {
    title: "Companies Management",
    icon: "briefcase",
    url: "/companies",
    isVisibleForRoles: ['Admin']

  },
  {
    title: "Property Management",
    icon: "building",
    url: "/properties",
    isVisibleForRoles: ['Admin']

  },
  {    
    title: "Unit Details",
    icon: "vcard",
    url: "/unitDetails",
    isVisibleForEntities: ['WNC']
  },
  {    
    title: "Unit Details CPP",
    icon: "vcard",
    url: "/unitDetailsCPP",
    isVisibleForEntities: ['CPP']
  },
  {
    title: "Occupancy Reports",
    icon: "bar-chart",
    url: "/reports",
    isVisibleForEntities: ['CPP']
  },
  {
    title: "Preventive Maintenance(CPP)",
    icon: "line-chart",
    url: "/preventiveMaintenance",
    isVisibleForEntities: ['CPP']
  },
  {
    title: "Preventive Maintenance(PEF)",
    icon: "line-chart",
    url: "/preventiveMaintenancePEF",
    isVisibleForEntities: ['PEF']
  },
  {
    title: "Occupancy Reports PEF",
    icon: "bar-chart",
    url: "/reportsPEF",
    isVisibleForEntities: ['PEF']
  },
  {
    title: "Role Management",
    icon: "id-badge",
    url: "/roleManagement",
    isVisibleForRoles: ['Admin']
  },
  {
    title: "Preventive Maintenance Dashboard(CPP)",
    icon: "tasks",
    url: "/preventiveMaintenanceDashboard",
    isVisibleForEntities: ['CPP']
  },
  {
    title: "Preventive Maintenance Dashboard(PEF)",
    icon: "tasks",
    url: "/preventiveMaintenanceDashboardPEF",
    isVisibleForEntities: ['PEF']
  }


]
