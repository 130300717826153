import { Component, TemplateRef, Output, EventEmitter, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DialogsService } from '@app/core/services/dialogs.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TrackingItemAddDialogService } from '../tracking-item-add-dialog/tracking-item-add-dialog.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TrackingItem, TrackingItemObject } from '@app/core';
//import { AnimateChildAst } from '@angular/animations/browser/src/dsl/animation_ast';
import { PreventiveMaintenanceService } from '@app/preventiveMaintenance/preventiveMaintenance.service';
import { FrequencyType } from '@app/core/models/frequencyType';
import { Files } from '@app/core/models/files';
import { FileUploaderComponent } from '@app/shared/components/file-uploader/file-uploader.component';
import { FrequencyAddDialogComponent } from '../frequency-add-dialog/frequency-add-dialog.component';
import { FilesDialogComponent } from '../files-dialog/files-dialog.component';

@Component({
  selector: 'tracking-item-add-dialog',
  templateUrl: './tracking-item-add-dialog.component.html',
  styleUrls: ['./tracking-item-add-dialog.component.css'],
})
export class TrackingItemAddDialogComponent implements OnInit { 

  @Input()
  @Output() closeModalEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild(FileUploaderComponent) child!: FileUploaderComponent;

  public eventCloseModal: EventEmitter<any> = new EventEmitter();
  public newTrackingItemShowDiv: boolean = false;
  public isFailedForm: boolean = false;
  public newTrackingItem: TrackingItem = new TrackingItemObject();
  public trackingItems: TrackingItem[] = [];
  public entityId: number;
  public frequencyTypeList: FrequencyType[] = [];
  public repeatedForms: any;
  @Input() file!: any;
  public params:any;
  public fileList: Files[] = [];
  public NewTrackingItemObject: TrackingItem = new TrackingItemObject();
  public isAdd = false;
  public editFrequencyTypePopover: string = "Push the '+' button to edit the list of frequency types."
  public entity: string;


  constructor(
    private modalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    public activeModal: NgbActiveModal,
    public ngbModal: NgbModal,
    private trackingItemService: TrackingItemAddDialogService,
    private dialogsService: DialogsService,
    private preventiveMaintenanceService: PreventiveMaintenanceService,
    public filesDialogComponent: FilesDialogComponent,
    ) { 

  }
  //getDownloadURL = this.filesDialogComponent.getDownloadURL;
  ngOnInit() {
    this.entity = this.entityId==3?"pef":"cpp";
    this.getFrequencyTypes();
  }

  
  public onHeaderChange(){

  }

  public successFileUpload(file: any) {
    this.file = file;
    if(file.fileId > 0){
      if(this.isAdd === true){
        this.NewTrackingItemObject.filePath = file.filePath;
        this.NewTrackingItemObject.fileName = file.name;
        this.NewTrackingItemObject.fileID = file.fileId;
        const found = this.trackingItems.some(el => el.trackingItemID === this.NewTrackingItemObject.trackingItemID);
        if(found === false){
          this.trackingItems.push(this.NewTrackingItemObject);
        }
        this.closeNewTrackingItemDiv();
      } else {
        this.trackingItems = [];
        this.getTrackingItems();
      }
      this.file = null;
    }
  }

  //TRACKING ITEM METHODS//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  private getTrackingItems(){
    this.preventiveMaintenanceService.getTrackingItems(this.entityId).subscribe((res) => {
      this.trackingItems = res.list;
    });
  }

  public showNewTrackingItemDiv(){
    this.newTrackingItemShowDiv = true;
  }

  public closeNewTrackingItemDiv(){
    this.newTrackingItemShowDiv = false;
    this.newTrackingItem = new TrackingItemObject();
  }

  public saveTrackingItem(item: TrackingItem){
    item.entityId = this.entityId;
    if(item.trackingItemName === ''){
      this.dialogsService.alert('error', 'The tracking name cannot be blank.');
      this.getTrackingItems();
    } else {
      if(item.trackingItemID > 0){
        this.isAdd = false;
        this.trackingItemService.updateTrackingItem(item).subscribe(_res => {
          this.params ={};
          this.params.panel = 'trackingItem'
          this.params.foreignId = item.trackingItemID;
          if(item.fileID > 0){
            this.params.fileId = item.fileID;
          }
          this.NewTrackingItemObject = item;
          const title = 'Success';
          const message = `Tracking item succesfully saved.`;
          this.dialogsService.alert(title, message);
        })
      } else {
        this.isAdd = true;
        if(this.file){
          this.trackingItemService.addTrackingItem(item).subscribe(res => {
            this.params ={};
            this.params.panel = 'trackingItem'
            this.params.foreignId = res;
            item.trackingItemID = res;
            this.NewTrackingItemObject = item;
            const title = 'Success';
            const message = `Tracking item succesfully saved.`;
            this.dialogsService.alert(title, message);
          })
        } else {
          this.trackingItemService.addTrackingItem(item).subscribe(_res => {
            const title = 'Success';
            const message = `Tracking item succesfully saved.`;
            this.dialogsService.alert(title, message);
            this.closeNewTrackingItemDiv();
            this.getTrackingItems();
          })
        }
      }
    }
  }


  public deleteTrckingItem(item: TrackingItem, index: number){
    const popUpTitle = 'Delete Tracking Item' ;
    const message = `Are you sure you want to delete this tracking item?`;
    this.dialogsService.confirmation(popUpTitle, message)
      .then(() => {
        this.trackingItemService.deleteTrackingItem(item.trackingItemID).subscribe(_res=>{      
        const title = 'Success';
        const message = `Tracking item succesfully deleted`;
        this.dialogsService.alert(title, message);
          this.trackingItems.splice(index, 1);
        },(error) => {
          const title = 'Error';
          const message = error.error;
          this.dialogsService.alert(title, message)
        });
      });
  }

  public closeModal(){
    this.trackingItems = [];
    this.activeModal.dismiss();
    this.eventCloseModal.emit({});
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  //FREQUENCY TYPE METHODS//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private getFrequencyTypes(){
    this.preventiveMaintenanceService.getFrequencyTypes().subscribe(res=>{
      this.frequencyTypeList = res;
    });
  }

  public editFrequencyType(){
    this._openFrequencyAddDialog()
      .then(() => {
    }, (_onClose: any) => {
      this.preventiveMaintenanceService.getFrequencyTypes().subscribe((res) => {
        this.frequencyTypeList = res;
      })
    });
  }

  private _openFrequencyAddDialog() {
    const modalRef = this.ngbModal.open(FrequencyAddDialogComponent,{
      size: 'lg',
      //windowClass: "frequencySize", ****this is in the sytle.css sheet. just in case things change and needs custom size
      //BFS: 1.01 - wont close the modal if you cilck outside of the modal box
      backdrop: 'static'
    });
    modalRef.componentInstance.frequencyTypeList = this.frequencyTypeList;

    modalRef.result.then((_data: any) => {
      this.getFrequencyTypes()
    }, (_reason: any) => {
      this.getFrequencyTypes()
    });
    return modalRef.result;
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
}