import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, Property, PropertyAdditionalTargeting, PropertyDeepRentSkewing } from '@app/core';
import { Observable } from 'rxjs';
import { PropertyJointVenturePartnerList } from '@app/core/models/propertyJointVenturePartnerList';

@Injectable({ providedIn: 'root' })
export class PropertyService {
  constructor(private http: HttpClient) { }

  getAll(isActive?: boolean) {
    var active = isActive == null ? '' : '/' + (isActive ? "true" : "false");
    return this.http.get<{ list: Property[] }>(`${AppConfig.apiEndpoint}/properties/1/5000${active}`);
  }

  getPropertyById(id: number){
    return this.http.get<Property>(`${AppConfig.apiEndpoint}/properties/getPropertyById/${id}`);
  }

  getPropertiesDropdownByEntity(){
    return this.http.get<{ list: Property[] }>(`${AppConfig.apiEndpoint}/properties/getPropertiesDropdownByEntity/1/5000`);
  }

  getPropertyAdditionalTargetings(id: number) {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/getPropertyAdditionalTargeting/${id}`);
  }

  addPropertyAdditionalTargeting(addTarget: PropertyAdditionalTargeting) {
    return this.http.post(`${AppConfig.apiEndpoint}/properties/addPropertyAdditionalTargeting`, addTarget);
  }

  updatePropertyAdditionalTargeting(addTarget: PropertyAdditionalTargeting) {
    return this.http.put(`${AppConfig.apiEndpoint}/properties/updatePropertyAdditionalTargeting`, addTarget);
  }

  deletePropertyAdditionalTargeting(id: number) {
    return this.http.delete(`${AppConfig.apiEndpoint}/properties/deletePropertyAdditionalTargeting/${id}`);
  }

  getPropertyDeepRentSkewings(id: number) {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/getPropertyDeepRentSkewing/${id}`);
  }

  addPropertyDeepRentSkewing(deepRent: PropertyDeepRentSkewing) {
    return this.http.post(`${AppConfig.apiEndpoint}/properties/addPropertyDeepRentSkewing`, deepRent);
  }

  updatePropertyDeepRentSkewing(deepRent: PropertyDeepRentSkewing) {
    return this.http.put(`${AppConfig.apiEndpoint}/properties/updatePropertyDeepRentSkewing`, deepRent);
  }

  deletePropertyDeepRentSkewing(id: number) {
    return this.http.delete(`${AppConfig.apiEndpoint}/properties/deletePropertyDeepRentSkewing/${id}`);
  }

  getById(id: number) {
    return this.http.get(`${AppConfig.apiEndpoint}/properties/${id}`);
  }

  add(property: Property) {
    return this.http.post(`${AppConfig.apiEndpoint}/properties`, property);
  }

  update(user: Property) {
    return this.http.put(`${AppConfig.apiEndpoint}/properties`, user);
  }
  
  delete(id: number) {
    return this.http.delete(`${AppConfig.apiEndpoint}/properties/${id}`);
  }

  getAllCompanies() {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/companies/all`);
  }

  getAllConstructionTypes() {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/getConstructionTypes`);
  }

  getAllRehabTypes() {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/getRehabTypes`);
  }

  getAllTenantTypes() {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/getTenantTypes`);
  }

  getAllSetAsideTypes() {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/getSetAsideTypes`);
  }

  getAllStates() {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/getStates`);
  }

  getCounties(state: string) {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/getCounties/${state}`);
  }

  getAllParntershipList() {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/getPartnershipList`);
  }

  getPartnershipValidList() {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/getPartnershipValidList`);
  }

  getAllPropertyJointVentureParnterList() {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/getPropertyJointVenturePartnerList`);
  }

  addPropertyJointVenturePartner(propertyJointVenturePartner: any) {
    return this.http.post(`${AppConfig.apiEndpoint}/properties/addPropertyJointVenturePartner`, propertyJointVenturePartner);
  }

  getAllSubsidyTypes() {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/getSubsidyTypes`);
  }

  getAllComplianceAssignedTo() {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/getComplianceAssignedTo`)
  }

  getAllCPPAssignedTo() {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/getCPPAssignedTo`)
  }

  getAllInvestorList() {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/getInvestorList`);
  }

  addInvestor(investor: any) {
    return this.http.post(`${AppConfig.apiEndpoint}/properties/addInvestor`, investor);
  }

  getPropertyOnPartnershipSelect(id: number) {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/getPropertyOnPartnershipSelect/${id}`);
  }

  getPropertyRentSubsidyOnPartnershipSelect(id: number) {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/getPropertyRentSubsidyOnPartnershipSelect/${id}`);
  }

  getPropertyLoanAndSubsidyGrantTypeOnPartnershipSelect(id: number) {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/getPropertyLoanAndSubsidyGrantTypeOnPartnershipSelect/${id}`);
  }
}
